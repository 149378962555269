<div *ngIf="categorySectionData?.type !== 'categories_with_banner'" class="categories-section">
    <div class="categories_list_sections" *ngFor="let category of categorySectionData?.categories"
        [ngStyle]="{ 'background-color' : category.color_code }" tabindex="0" (click)="handleClickRoute(category)" (keypress)="handleClickRoute(category)">
        <div class="img">
            <img loading="lazy"  [src]="category.icon" [alt]="category.title">
        </div>
        <div class="title">
            <span>{{ category.title }}</span>
        </div>
    </div>
</div>
<div *ngIf="categorySectionData?.type === 'categories_with_banner'" class="catwith_banner_listcats dashain_bgs">
    <div class="container" *ngIf="categorySectionData.isVisible">
        <div class="wrappingfullwidth_catses">
            <div class="catwith-bannersec" tabindex="0" (click)="categorySectionData.banner ? handleClickRoute(categorySectionData.banner) : null" (keypress)="categorySectionData.banner ? handleClickRoute(categorySectionData.banner) : null">
                <img loading="lazy" [src]="categorySectionData.banner?.image" *ngIf="isHandset$ | async" alt="Buy Makeup Products Online | Foreveryng">
                <img loading="lazy" [src]="categorySectionData.banner?.web_image" *ngIf="(isHandset$ | async) === false" [alt]="categorySectionData.title">
            </div>
            <div class="catwith-bannersec-catlists">
                <div class="category" *ngFor="let category of categorySectionData?.categories"
                    [ngStyle]="{ 'background-color' : category.color_code }" tabindex="0" (click)="handleClickRoute(category)" (keypress)="handleClickRoute(category)">
                    <div class="img">
                        <img loading="lazy"  [src]="category.image" [alt]="category.title">
                    </div>
                    <div class="title">
                        <span>{{ category.title }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>