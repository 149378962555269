import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { home_brands_section } from 'src/app/core/interface/brand';

@Component({
  selector: 'app-brands-section',
  templateUrl: './brands-section.component.html',
  styleUrls: ['./brands-section.component.scss']
})
export class BrandsSectionComponent {
  @Input() brandSectionData: home_brands_section[] = [];
  activeTab = 0;

  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor (
    private _breakpointObserver: BreakpointObserver
  ) {}
}
