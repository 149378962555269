import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { AppState } from 'src/app/store/app.store';
import * as HomeSelector from 'src/app/store/home/home.selectors';
import { HomeActions } from 'src/app/store/home/home.actions';
import { home } from 'src/app/core/interface/home';
import { home_banners_section } from 'src/app/core/interface/banner';
import { home_categories_section } from 'src/app/core/interface/category';
import { home_products_section } from 'src/app/core/interface/product';
import { home_brands_section } from 'src/app/core/interface/brand';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  homeData$: Observable<home> = EMPTY;
  bannerData$: Observable<home_banners_section[]> = EMPTY;
  categoryData$: Observable<home_categories_section[]> = EMPTY;
  productData$: Observable<home_products_section[]> = EMPTY;
  brandData$: Observable<home_brands_section[]> = EMPTY;

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _store: Store<AppState>,
    private _title: Title
  ) { }

  ngOnInit(): void {
    this._title.setTitle('Nepal\'s Largest Beauty Ecommerce | Foreveryng');
    localStorage.removeItem('affiliate_type');
    localStorage.removeItem('affiliate_id');
    window.fbq('track', 'ViewContent');
    this._store.dispatch(HomeActions.loadHome());
    this.homeData$ = this._store.select(HomeSelector.getHomeData);
    this.bannerData$ = this._store.select(HomeSelector.getHomeBanners);
    this.brandData$ = this._store.select(HomeSelector.getHomeBrands);
    this.productData$ = this._store.select(HomeSelector.getHomeProducts);
    this.categoryData$ = this._store.select(HomeSelector.getHomeCategories);
  }

  categoriesSectionScroll = {
    showLeft: false,
    showRight: true,
    handleScrollButton(scrollElement: HTMLDivElement) {
      const firstChild = scrollElement.firstChild?.firstChild?.firstChild as HTMLDivElement;
      if (Math.floor(firstChild.getBoundingClientRect().left) >= Math.floor(scrollElement.getBoundingClientRect().left)) {
        this.showLeft = false;
        return;
      } else {
        this.showLeft = true;
      }
      const lastChild = scrollElement.firstChild?.nextSibling?.firstChild?.lastChild?.previousSibling as HTMLDivElement;
      if (Math.floor(lastChild.getBoundingClientRect().right) <= Math.floor(scrollElement.getBoundingClientRect().right)) {
        this.showRight = false;
        return;
      } else {
        this.showRight = true;
      }
    },  
    scrollCatLeft(scrollElement: HTMLDivElement) {
      this.handleScrollButton(scrollElement);
      this.showRight = true;
      scrollElement.scrollBy({ left: -120 });
    },  
    scrollCatRight(scrollElement: HTMLDivElement) {
      this.handleScrollButton(scrollElement);
      this.showLeft = true;
      scrollElement.scrollBy({ left: 120 });
    }
  }
}
