<div [ngClass]="noBackgroundBanners.includes(bannerSectionData.type) ? 'nobackground_sections' : 'banners_with_descriptions_nobgs'">
    <div class="container">
        <h2>{{ bannerSectionData.title }}</h2>
        <div class="wrap_bannerdescrptions">
            <ng-container *ngFor="let banner of bannerSectionData.banners">
                <div class="blocks_banners_sec" (click)="bannerClick(banner)" (keypress)="bannerClick(banner)" tabindex="0">
                    <div class="imgsec_banner_dscp">
                        <img [src]="banner.image" alt="">
                    </div>
                    <div *ngIf="banner.bannerTitleDetails.bannerTitle && banner.bannerSubTitleDetails.bannerSubTitle" [ngClass]="noBackgroundBanners.includes(bannerSectionData.type) ? 'nobackground_dscrpt' : 'blocks_banners_discnts'">
                        <h2 [ngStyle]="{ color: banner.bannerTitleDetails.color }" >{{ banner.bannerTitleDetails.bannerTitle }}</h2>
                        <p [ngStyle]="{ color: banner.bannerSubTitleDetails.color }" >{{ banner.bannerSubTitleDetails.bannerSubTitle }}</p>
                        <span class="arrow_links"></span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
