@if (deviceView === 'website') {
    <button class="btn left-btn" (click)="prevImage()">prev</button>
}
<div class="carousel-images-list" [ngStyle]="{ '--image-pos': activeImage, '--image-width': width, '--image-height': height }">
    @for (banner of banners; track $index) {
        <img
          (touchstart)="touchStart($event)" (touchend)="touchEnd($event)"
          [src]="banner.image" (click)="redirectBanner(banners[activeImage])"
        >
    }
</div>
@if (deviceView === 'website') {
    <button class="btn right-btn" (click)="nextImage()">next</button>
}
