import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay } from 'rxjs';
import { banner, home_banners_section } from 'src/app/core/interface/banner';
import { ClickRouteService } from 'src/app/core/services/click-route.service';

@Component({
  selector: 'app-banners-section',
  templateUrl: './banners-section.component.html',
  styleUrls: ['./banners-section.component.scss']
})
export class BannersSectionComponent implements OnInit {
  @Input() bannerSectionData: home_banners_section = {
    id: 0,
    title: '',
    content: '',
    isVisible: false,
    type: '',
    displaySeeAll: false,
    banners: []
  };
  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  currentSlideIndex = 0;
  bannersLength = 0;
  touchEvent = {
    start: 0
  }
  slideInterval: any;
  justArrivedLeftExists$ = new BehaviorSubject<boolean>(false);
  justArrivedRightExists$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _clickRoute: ClickRouteService
  ) { }

  descriptionBanners = [
    'main_banner',
    'our_exclusive_1',
    // 'our_exclusive_2',
    'refer_and_earn',
    'category_banner',
  ];


  adBanners = [
    'ad_banner',
    'main_ad_banner',
    'ad_banner_1',
    'ad_banner_2',
    'ad_banner_3',
    'ad_banner_4'
  ];
  largeBanners = [
    // 'main_banner',
    // 'our_exclusive_1',
    // 'our_exclusive_2',
    'grouped_product',
    'flash_sale',
    'offer_full_banner',
    'question_answers_banner',
    'brand_list_banner',
    'collaboration_main_banner',
    'terms_and_conditions',
    'free_gifts',
    // 'refer_and_earn',
    'makeup_artist_main_slider'
  ];

  smallBanners = [
    // 'ad_banner',
    // 'main_ad_banner',
    // 'ad_banner_1',
    // 'ad_banner_2',
    // 'ad_banner_3',
    'terms_and_conditions',
  ];

  ngOnInit() {
    this.slideInterval = setInterval(() => this.slideNext(), 5000);
    this.bannersLength = this.bannerSectionData?.banners?.length;
  }

  slideNext() {
    this.currentSlideIndex = (this.currentSlideIndex + 1) % this.bannersLength;
  }

  slidePrev() {
    this.currentSlideIndex > 0 ? this.currentSlideIndex = this.currentSlideIndex - 1 : this.currentSlideIndex = this.bannersLength - 1;
  }

  touchStart(e: TouchEvent) {
    this.touchEvent.start = e.changedTouches[0].clientX;
  }

  touchEnd(e: TouchEvent) {
    if ((e.changedTouches[0].clientX - this.touchEvent.start) > 50) {
      this.slidePrev();
    }
    if ((e.changedTouches[0].clientX - this.touchEvent.start) < -50) {
      this.slideNext();
    }
    clearInterval(this.slideInterval);
    this.slideInterval = setInterval(() => this.slideNext(), 5000);
  }

  bannerClick(banner: banner) {
    this._clickRoute.handleClick(banner.attribute, banner.filter, banner.id, '', '', banner.bannerId, banner.banner_type);
  }

  scrollJustArrivedLeft() {
    const container = document.getElementById('just-arrived-list');
    container?.scrollBy({ left: -container.clientWidth });
  }

  scrollJustArrivedRight() {
    const container = document.getElementById('just-arrived-list');
    container?.scrollBy({ left: container.clientWidth });
  }


  scrollBrandOfTheDayLeft() {
    const container = document.getElementById('brand-of-the-day');
    container?.scrollBy({ left: -container.clientWidth });
  }

  scrollBrandOfTheDayRight() {
    const container = document.getElementById('brand-of-the-day');
    container?.scrollBy({ left: container.clientWidth });
  }

  scrollDealsToLookOutLeft() {
    const container = document.getElementById('deals-to-lookout');
    container?.scrollBy({ left: -container.clientWidth });
  }

  scrollDealsToLookOutRight() {
    const container = document.getElementById('deals-to-lookout');
    container?.scrollBy({ left: container.clientWidth });
  }


  scrollOurExclusive2Left() {
    const container = document.getElementById('our-exclusive-2');
    container?.scrollBy({ left: -container.clientWidth });
  }

  scrollOurExclusive2Right() {
    const container = document.getElementById('our-exclusive-2');
    container?.scrollBy({ left: container.clientWidth });
  }

  scrollBannerLeft(banner: string) {
    const container = document.getElementById(banner);
    container?.scrollBy({ left: -1075 });
  }

  scrollBannerRight(banner: string) {
    const container = document.getElementById(banner);
    container?.scrollBy({ left: 1075 });
  }
}
