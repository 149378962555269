<div class="membership_info" [ngStyle]="{ '--current': currentPoints > totalPoints ? totalPoints : currentPoints, '--total': totalPoints }">
  <div class="title">
    <h2>Your Membership Journey</h2>
    <a [routerLink]="['/membership']" class="explore-btn">Explore Benefits</a>
  </div>

  <div class="progress-container">
    <div class="progress-bar">
      <img class="progess-pointer" src="../../../../../assets/icons/lipstick.png">
      <span class="pointer-text">{{ (userInfo?.name ?? 'you').split(' ')[0] }}</span>
      <div class="progress" [ngStyle]="{'--current-color': userInfo?.membership?.detail?.color ?? 'gray' }"></div>
      <div class="milestones">
        <div class="initial"></div>
        @for (type of membershipData; track $index) {
          <div class="checkpoint" [ngClass]="{'active': currentPoints >= type.minimum_point }" [ngStyle]="{ '--checkpoint': type.minimum_point, '--checkpoint-color': type.color , '--checkpoint-color-inactive': type.color+'ee' }"></div>
          <span class="checkpoint-text" [ngStyle]="{ '--checkpoint': type.minimum_point }">{{ type.title }}</span>
        }
      </div>
    </div>
  </div>
</div>
