import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, map, shareReplay } from 'rxjs';
import { home_products_section } from 'src/app/core/interface/product';

@Component({
  selector: 'app-products-section',
  templateUrl: './products-section.component.html',
  styleUrls: ['./products-section.component.scss']
})
export class ProductsSectionComponent implements OnInit {
  @Input() productSectionData: home_products_section = {
    id: 0,
    title: '',
    isVisible: false,
    type: '',
    startTime: 0,
    endTime: 0,
    displaySeeAll: false,
    products: []
  };
  @Input() hideTitle = false;
  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private _breakpointObserver: BreakpointObserver
  ) { }

  remainingTime$ = new BehaviorSubject<any>(EMPTY);

  ngOnInit() {
    if (this.productSectionData) {
      setInterval(() => {
        const time = this.productSectionData.endTime * 1000 - new Date().getTime();
        const days = Math.floor(time / (1000 * 3600 * 24));
        const hours = Math.floor(time / (1000 * 3600)) - days * 24;
        const min = Math.floor(time / (1000 * 60)) - hours * 60 - days * 24 * 60;
        const sec = Math.floor(time / 1000) - min * 60 - hours * 3600 - days * 24 * 3600;
        this.remainingTime$.next({
          dayTens: Math.floor(days / 10),
          dayOnce: days % 10,
          hrsTens: Math.floor(hours / 10),
          hrsOnce: hours % 10,
          minTens: Math.floor(min / 10),
          minOnce: min % 10,
          secTens: Math.floor(sec / 10),
          secOnce: sec % 10
        })
      }, 1000)
    }
  }

  productsSectionScroll = {
    showLeft: false,
    showRight: true,
    handleScrollButton(scrollElement: HTMLDivElement) {
      const firstChild = scrollElement.firstChild?.firstChild?.firstChild as HTMLDivElement;
      if (Math.floor(firstChild.getBoundingClientRect().left) >= Math.floor(scrollElement.getBoundingClientRect().left)) {
        this.showLeft = false;
        return;
      } else {
        this.showLeft = true;
      }
      const lastChild = scrollElement.lastChild?.previousSibling as HTMLDivElement;
      if (Math.floor(lastChild.getBoundingClientRect().right) <= Math.floor(scrollElement.getBoundingClientRect().right)) {
        this.showRight = false;
        return;
      } else {
        this.showRight = true;
      }
    },
    scrollPrev(scrollElement: HTMLDivElement) {
      this.handleScrollButton(scrollElement);
      this.showRight = true;
      scrollElement.scrollBy({ left: -207 });
    },
    scrollNext(scrollElement: HTMLDivElement) {
      this.handleScrollButton(scrollElement);
      this.showLeft = true;
      scrollElement.scrollBy({ left: 207 });
    }
  }
}
