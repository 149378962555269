import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { home_categories_section } from 'src/app/core/interface/category';
import { ClickRouteService } from 'src/app/core/services/click-route.service';

@Component({
  selector: 'app-categories-section',
  templateUrl: './categories-section.component.html',
  styleUrls: ['./categories-section.component.scss']
})
export class CategoriesSectionComponent {
  @Input() categorySectionData: home_categories_section = {
    id: 0,
    title: '',
    content: '',
    isVisible: false,
    type: '',
    banner: null,
    displaySeeAll: false,
    categories: []

  };
  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
      private _breakpointObserver: BreakpointObserver,
      private _handleClick: ClickRouteService
  ) { }

  handleClickRoute(obj: { attribute: string, filter?: NonNullable<unknown> | null, id: number, title: string, slug?: string }) {
    obj.filter ?  this._handleClick.handleClick(obj.attribute, obj.filter, obj.id, obj.title, obj.slug) : this._handleClick.handleClick(obj.attribute, null, obj.id, obj.title, obj.slug);
  }
}
