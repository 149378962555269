import { JsonPipe, NgClass, NgStyle } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { API_ROUTES } from 'src/app/core/constants/api.constants';
import { user } from 'src/app/core/interface/user';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Component({
  selector: 'app-membership-progress',
  standalone: true,
  imports: [NgStyle, RouterLink, NgClass],
  templateUrl: './membership-progress.component.html',
  styleUrl: './membership-progress.component.scss'
})
export class MembershipProgressComponent implements OnInit{
  @Input({ required: true }) userInfo!: user | null;
  membershipData: any;
  private _api = inject(ApiHttpService);
  currentPoints = 0;
  totalPoints = 0;

  ngOnInit(): void {
    this.currentPoints = this.userInfo?.membership.point ?? 0;
    this._api.get(API_ROUTES.membershipDetail).subscribe({
      next: (res:any) => {
        this.membershipData = res.data
        this.totalPoints = res.data[res.data.length - 1].minimum_point;
        this.currentPoints = this.currentPoints > this.totalPoints ? this.totalPoints : this.currentPoints;
      }
    });
  }
}
