import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { HomeSectionComponent } from './home-section/home-section.component';
import { ProductsSectionComponent } from './products-section/products-section.component';
import { BrandsSectionComponent } from './brands-section/brands-section.component';
import { CategoriesSectionComponent } from './categories-section/categories-section.component';
import { BannersSectionComponent } from './banners-section/banners-section.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { BannersDescriptionComponent } from './banners-section/banners-description/banners-description.component';
import { AddBannersComponent } from './banners-section/add-banners/add-banners.component';
import { BrandOfTheDayComponent } from './banners-section/brand-of-the-day/brand-of-the-day.component';
import { HomePopupsComponent } from './home-popups/home-popups.component';
import { BannerCarouselComponent } from 'src/app/shared/components/banner-carousel/banner-carousel.component';
import { MembershipProgressComponent } from './home-section/membership-progress/membership-progress.component';


@NgModule({
  declarations: [
    HomeComponent,
    HomeSectionComponent,
    ProductsSectionComponent,
    BrandsSectionComponent,
    CategoriesSectionComponent,
    BannersSectionComponent,
    BannersDescriptionComponent,
    AddBannersComponent,
    BrandOfTheDayComponent,
    HomePopupsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    HomeRoutingModule,
    BannerCarouselComponent,
    MembershipProgressComponent
  ],
  exports: [
    HomeSectionComponent
  ]
})
export class HomeModule { }
