import { Component, Input, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { API_ROUTES } from 'src/app/core/constants/api.constants';
import { home } from 'src/app/core/interface/home';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { ClickRouteService } from 'src/app/core/services/click-route.service';
import { NoticeService } from 'src/app/core/services/notice.service';
import { AppState } from 'src/app/store/app.store';
import { UserActions } from 'src/app/store/user/user.actions';
const popup = 'popupSession';

@Component({
  selector: 'app-home-popups',
  templateUrl: './home-popups.component.html',
  styleUrls: ['./home-popups.component.scss'],
})
export class HomePopupsComponent implements OnInit {
  private readonly _redirect = inject(ClickRouteService);
  private readonly _api = inject(ApiHttpService);
  private readonly _store = inject(Store<AppState>);
  private readonly _notice = inject(NoticeService);
  @Input() homeData!: home;
  viewPopup = true;
  popuptype!: 'newUser' | 'dailyReward' | 'homePopup' | 'homePopup1' | 'remindNewUser';
  now = 0;
  popupDate: { [key: string]: number } = {
    newUser: 0,
    dailyReward: 0,
    homePopup: 0,
    homePopup1: 0,
    remindNewUser: 0
  };

  ngOnInit(): void {
    this.now = Date.now() / 1000;
    const data = sessionStorage.getItem(popup) ?? '{}';
    this.popupDate = {
      ...this.popupDate,
      ...JSON.parse(data),
    };

    if (this.homeData.newUserRewardPopUp.showPopUp && (this.now - this.popupDate['newUser']) > 3600) {
      this.popuptype = 'newUser';
    } else if (this.homeData.newUserPromoCodeUsageReminderPopUp?.showPopUp && (this.now - this.popupDate['remindNewUser']) > 1800) {
      this.popuptype = 'remindNewUser';
    // } else if (this.homeData.dailyVisitRewardPopUp.showPopUp && (this.now - this.popupDate['dailyReward']) > 3600) {
    //   this.popuptype = 'dailyReward';
    } else if (this.homeData.popup.showPopUp && this.homeData.popup.webImage && (this.now - this.popupDate['homePopup']) > 3600) {
      this.popuptype = 'homePopup';
    } else if (this.homeData.popup1.showPopUp && this.homeData.popup1.webImage && (this.now - this.popupDate['homePopup1']) > 3600) {
      this.popuptype = 'homePopup1';
    } else {
      this.viewPopup = false;
    }
  }

  popupClose() {
    this.popupDate = {
      ...this.popupDate,
      [this.popuptype]: Date.now()/1000
    };
    sessionStorage.setItem(popup, JSON.stringify(this.popupDate));
    if (this.popuptype === 'homePopup') {
      this.ngOnInit();
      return;
    }
    this.viewPopup = false;
  }

  claimNewUserReward() {
    this._api.post(API_ROUTES.claimNewUserReward).subscribe({
      next: (res: any) => this._notice.addNotice(res.message, 'success', 20),
      error: (err) => {
        this._notice.addNotice(err.error.message, 'error');
        this._store.dispatch(UserActions.loadUser());
      },
      complete: () => this._store.dispatch(UserActions.loadUser())
    });
    this.popupClose();
  }

  claimDailyReward() {
    this._api.post(API_ROUTES.claimDailyReward).subscribe({
      next: (res: any) => this._notice.addNotice(res.message),
      error: (err) => {
        this._notice.addNotice(err.error.message, 'error');
        this._store.dispatch(UserActions.loadUser());
      },
      complete: () => this._store.dispatch(UserActions.loadUser())
    });
    this.popupClose();
  }

  homePopupClicked() {
    this._redirect.handleClick(this.homeData.popup.attribute, this.homeData.popup.filter, this.homeData.popup.id, 'banner-redirect', 'banner-redirect')
    this.popupClose();
  }

  remindNewUserPopupClicked() {
    this._redirect.handleClick(this.homeData.newUserPromoCodeUsageReminderPopUp.attribute, this.homeData.newUserPromoCodeUsageReminderPopUp.filter, this.homeData.newUserPromoCodeUsageReminderPopUp.id, 'redirect', 'redirect')
    this.popupClose();
  }
}
