<div class="brandsWraps_sections">
    <div class="container">
        <div class="wrap_brands_sec" [ngClass]="(isHandset$ | async) ? 'brand-list-handset' : 'brand-list'">
            <!-- <h3>{{brandSectionData[0].title}}</h3> -->
            <ng-container *ngFor="let brandSection of brandSectionData; index as i">
                <a class="brand" *ngFor="let brand of brandSection.brands" [routerLink]="['/brand', brand.slug, brand.id]">
                    <img loading="lazy"  [src]="brand.image" [alt]="brand.title" [ngStyle]="{ 'max-width': (isHandset$ | async) ? '100px' : '150px' }"
                    [alt]="brand.title">
                </a>
            </ng-container>
        </div>
    </div>
</div>