import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay } from 'rxjs';
import { banner, home_banners_section } from 'src/app/core/interface/banner';
import { ClickRouteService } from 'src/app/core/services/click-route.service';


@Component({
  selector: 'app-add-banners',
  templateUrl: './add-banners.component.html',
  styleUrls: ['./add-banners.component.scss']
})
export class AddBannersComponent implements OnInit {
  @Input() bannerSectionData: home_banners_section = {
    id: 0,
    title: '',
    content: '',
    isVisible: false,
    type: '',
    displaySeeAll: false,
    banners: []
  };
  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  bannerSlider = {
    index: 0,
    length: 0,
    slideNext() {
      this.index = (this.index + 1) % this.length;
    },
    slidePrev() {
      this.index = this.index > 0 ? this.index - 1 : this.length - 1;
    }
  }

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _clickRoute: ClickRouteService
  ) { }

  ngOnInit(): void {
    this.bannerSlider = {
      ...this.bannerSlider,
      length: this.bannerSectionData.banners.length
    };

    setInterval(() => this.bannerSlider.slideNext(), 5000);
  }
  bannerClick(banner: banner) {
    this._clickRoute.handleClick(banner.attribute, banner.filter, banner.id, '', '', banner.bannerId, banner.banner_type);
  }
}
