<!-- main slider handset -->
<div style="width: calc(100% - 16px);">
    <div class="main-slider"
        *ngIf="bannerSectionData && (isHandset$ | async) && bannerSectionData.type === 'main_slider' && bannerSectionData.banners.length > 0">
        <div class="slide" [ngClass]="i === currentSlideIndex ? 'active' : ''"
            *ngFor="let banner of bannerSectionData.banners; index as i">
            <img loading="lazy" [src]="banner.image" alt="Buy Makeup Products Online | Foreveryng"
                (touchstart)="touchStart($event)" (touchend)="touchEnd($event)" tabindex="0"
                (click)="bannerClick(banner)" (keypress)="bannerClick(banner)">
        </div>
    </div>
</div>

<div class="main-slider-web"
    *ngIf="bannerSectionData && (isHandset$ | async) === false && bannerSectionData.type === 'main_slider' && bannerSectionData.banners.length > 0">
    <app-banner-carousel [banners]="bannerSectionData.banners" width="1600px" height="477px"></app-banner-carousel>
</div>

<ng-container *ngIf="bannerSectionData && descriptionBanners.includes(bannerSectionData.type) && bannerSectionData.banners.length > 0">
    <app-banners-description [bannerSectionData]="bannerSectionData"></app-banners-description>
</ng-container>

<ng-container *ngIf="bannerSectionData && adBanners.includes(bannerSectionData.type)">
    <app-add-banners [bannerSectionData]="bannerSectionData"></app-add-banners>
</ng-container>

<ng-container *ngIf="bannerSectionData && bannerSectionData.type === 'our_exclusive_2'">
    <div class="dealtolookout_secs">
        <div class="container">
            <div class="section-title">
                <h3>{{ bannerSectionData.title }}</h3>
                <div class="dealtolookout_secs_scroller">
                    <span tabindex="0" (click)="scrollOurExclusive2Left()" (keypress)="scrollOurExclusive2Left()">
                        <mat-icon class="mat-18">chevron_left</mat-icon>
                    </span>
                    <span tabindex="0" (click)="scrollOurExclusive2Right()" (keypress)="scrollOurExclusive2Right()">
                        <mat-icon class="mat-18">chevron_right</mat-icon>
                    </span>
                </div>
            </div>
            <ng-container>
                <div class="wrap_dealstolooks_secs" id="our-exclusive-2">
                    <div *ngFor="let banner of bannerSectionData.banners" class="list_banner_block" tabindex="0"
                        (click)="bannerClick(banner)" (keypress)="bannerClick(banner)">
                        <img loading="lazy"  [src]="banner.image" alt="{{ banner.title }}"
                            style="width: 100%;">
                        <div class="deals_discountstitle">
                            <h3>
                                {{banner.title}}</h3>
                            <p>{{banner.content}}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<!-- brands of the day -->
<div class="brand-of-the-day"
    *ngIf="bannerSectionData && bannerSectionData && bannerSectionData.type === 'brand_of_the_day' && bannerSectionData.banners.length > 0">
    <div class="container">
        <app-brand-of-the-day [bannerSectionData]="bannerSectionData"></app-brand-of-the-day>
    </div>
</div>

<!-- <ng-container
    *ngIf="(isHandset$ | async) && largeBanners.includes(bannerSectionData.type) && bannerSectionData.banners.length > 0">
    <div class="bannersec_thirdblocks">
        <h3 class="bannerheading_title">{{ bannerSectionData.title }}</h3>
    </div>
    <div class="blocks_list_banner_mblgit "
        [ngStyle]="{'width': (isHandset$ | async) ? 'calc(100vw - 16px)' : 'calc(100vw - 32px)'}">
        <div class="banner" *ngFor="let banner of bannerSectionData.banners">
            <div tabindex="0" (click)="bannerClick(banner)" (keypress)="bannerClick(banner)" style="width: 100%;">
                <img loading="lazy" [src]="banner.image" alt="{{ banner.title }}" style="width: 100%; height: 100%; border-radius: 5px;
                    ">
                <div class="title">{{banner.banner_title}}</div>
                <div class="subtitle">{{banner.banner_subtitle}}</div>
            </div>
        </div>
    </div>
</ng-container> -->


<div [ngClass]="bannerSectionData && bannerSectionData.type+'_web'"
    *ngIf="bannerSectionData && (isHandset$ | async) === false && largeBanners.includes(bannerSectionData.type) && bannerSectionData.banners.length > 0">
    <div class="container">
        <div class="title refer_erarnseb">
            <h3>{{ bannerSectionData.title }}</h3>

        </div>
        <div class="banners-list-container" id="{{bannerSectionData.type+'_web'}}">
            <div class="single-banner-container" tabindex="0" (click)="bannerClick(banner)"
                (keypress)="bannerClick(banner)" *ngFor="let banner of bannerSectionData.banners">
                <img loading="lazy" width="594" height="255" [src]="banner.image" alt="{{ banner.title }}"
                    alt="Buy Makeup Products Online">
                <div class="title">{{banner.banner_title}}</div>
                <div class="subtitle">{{banner.banner_subtitle}}</div>
            </div>
        </div>
    </div>
</div>

<div [ngClass]="bannerSectionData.type+'_web'"
    *ngIf="bannerSectionData && (isHandset$ | async) === false && smallBanners.includes(bannerSectionData.type) && bannerSectionData.banners.length > 0">
    <div class="title">
        <span>{{ bannerSectionData.title }}</span>
    </div>
    <div class="banners-list-container">
        <div class="single-banner-container" tabindex="0" (click)="bannerClick(banner)" (keypress)="bannerClick(banner)"
            *ngFor="let banner of bannerSectionData.banners">
            <img loading="lazy" [src]="banner.image" alt="{{ banner.title }}">
            <div class="title">{{banner.banner_title}}</div>
            <div class="subtitle">{{banner.banner_subtitle}}</div>
        </div>
    </div>
</div>

<ng-container
    *ngIf="bannerSectionData && (isHandset$ | async) && smallBanners.includes(bannerSectionData.type) && bannerSectionData.banners.length > 0">
    <div style="display: flex; padding: 8px; gap: 8px; overflow: auto;"
        [ngStyle]="{'width': (isHandset$ | async) ? 'calc(100vw - 16px)' : 'calc(100vw - 32px)'}">
        <ng-container *ngFor="let banner of bannerSectionData.banners">
            <div tabindex="0" (click)="bannerClick(banner)" (keypress)="bannerClick(banner)">
                <img loading="lazy" [src]="banner.image" alt="{{ banner.title }}"
                    [ngStyle]="{ 'width' : bannerSectionData.banners.length === 1 ? '100%' : '70vw' }"
                    style="border-radius: 5px;">
                <div class="title">{{banner.banner_title}}</div>
                <div class="subtitle">{{banner.banner_subtitle}}</div>
            </div>
        </ng-container>
    </div>
</ng-container>

<!-- just arrived -->
<div class="just-arrived-sections"
    *ngIf="bannerSectionData && bannerSectionData.type === 'just_arrived' && bannerSectionData.banners.length > 0">
    <div class="container">
        <div class="section-header">
            <div class="section-title">
                {{ bannerSectionData.title }}
            </div>
            <div class="just-arrived-scroller">
                <ng-container *ngIf="(isHandset$ | async) === false">
                    <span tabindex="0" (click)="scrollJustArrivedLeft()" (keypress)="scrollJustArrivedLeft()">
                        <mat-icon class="mat-18">chevron_left</mat-icon>
                    </span>
                    <span tabindex="0" (click)="scrollJustArrivedRight()" (keypress)="scrollJustArrivedRight()">

                        <mat-icon class="mat-18">chevron_right</mat-icon>

                    </span>
                </ng-container>
            </div>
        </div>
        <div class="banners-list" id="just-arrived-list">
            <div class="banner" *ngFor="let banner of bannerSectionData.banners">
                <img loading="lazy" width="195" height="290" [src]="banner.image"
                    alt="Buy Makeup Products Online | Foreveryng" tabindex="0" (click)="bannerClick(banner)"
                    (keypress)="bannerClick(banner)">
            </div>
        </div>
    </div>
</div>

<!-- brands to obsess -->
<ng-container *ngIf="bannerSectionData && bannerSectionData.type === 'deal_to_lookout_for' && bannerSectionData.banners.length > 0">

    <div class="dealtolookout_secs">
        <div class="container">
            <div class="section-title">
                <h3>{{ bannerSectionData.title }}</h3>
                <div class="dealtolookout_secs_scroller">
                    <span tabindex="0" (click)="scrollDealsToLookOutLeft()" (keypress)="scrollDealsToLookOutLeft()">
                        <mat-icon class="mat-18">chevron_left</mat-icon>
                    </span>
                    <span tabindex="0" (click)="scrollDealsToLookOutRight()" (keypress)="scrollDealsToLookOutRight()">
                        <mat-icon class="mat-18">chevron_right</mat-icon>
                    </span>
                </div>
            </div>


            <ng-container>
                <div class="wrap_dealstolooks_secs" id="deals-to-lookout">
                    <div *ngFor="let banner of bannerSectionData.banners" class="list_banner_block" tabindex="0"
                        (click)="bannerClick(banner)" (keypress)="bannerClick(banner)">
                        <img loading="lazy"  [src]="banner.image" alt="{{ banner.title }}"
                            style="width: 100%;">
                        <div class="deals_discountstitle">
                            <h3>
                                {{banner.title}}</h3>
                            <p>{{banner.content}}</p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
