import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, map, shareReplay } from 'rxjs';
import { ClickRouteService } from 'src/app/core/services/click-route.service';
import { VideoPlayerComponent } from 'src/app/shared/components/video-player/video-player.component';
import { AppState } from 'src/app/store/app.store';
import { HomeActions } from 'src/app/store/home/home.actions';

@Component({
  selector: 'app-home-section',
  templateUrl: './home-section.component.html',
  styleUrls: ['./home-section.component.scss']
})
export class HomeSectionComponent implements OnInit {
  @Input() homeSectionData: any;
  viewPopUp = true;
  @Input() dataType = '';
  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  reasonToBuy = {
    colors: [
      '#DD346C',
      '#FFB362',
      '#88DD34'
    ],
    image: [
      "../../../../assets/images/reason-to-buy/makeup.png",
      "../../../../assets/images/reason-to-buy/skincare.png",
      "../../../../assets/images/reason-to-buy/haircare.png"
    ]
  }

  redirect = inject(ClickRouteService);

  constructor(
      private _breakpointObserver: BreakpointObserver,
      private _dialog: MatDialog,
      private _store: Store<AppState>
  ) { }

  ngOnInit() {
    if (sessionStorage.getItem('view_popup') === 'viewed') {
      this.viewPopUp = false;
    }
  }

  openVideo(video: string) {
    this._dialog.open(VideoPlayerComponent, {
      data: video
    })
  }

  closePopUp() {
    this._store.dispatch(HomeActions.closePopUp());
    sessionStorage.setItem('view_popup', 'viewed');
  }
}
