<div class="product-section"
    *ngIf="productSectionData && productSectionData.products && productSectionData.isVisible">
    <div class="container">
        <div class="section-header" *ngIf="(isHandset$ | async) === false && ! hideTitle">
            <h2 class="section-title">
                {{ productSectionData.title }}
            </h2>
            <div class="flash-sale-timer" *ngIf="productSectionData.type === 'flash_sales'">
                <div class="time-box">
                    <div class="two-set">
                        <div class="unit">
                            {{ (remainingTime$ | async).dayTens }}
                        </div>
                        <div class="unit">
                            {{ (remainingTime$ | async).dayOnce }}
                        </div>
                    </div>
                    <div>
                        Day
                    </div>
                </div>
                <div class="time-box">
                    <div class="two-set">
                        <div class="unit">
                            {{ (remainingTime$ | async).hrsTens }}
                        </div>
                        <div class="unit">
                            {{ (remainingTime$ | async).hrsOnce }}
                        </div>
                    </div>
                    <div>
                        Hour
                    </div>
                </div>
                <div class="time-box">
                    <div class="two-set">
                        <div class="unit">
                            {{ (remainingTime$ | async).minTens }}
                        </div>
                        <div class="unit">
                            {{ (remainingTime$ | async).minOnce }}
                        </div>
                    </div>
                    <div>
                        Minute
                    </div>
                </div>
                <div class="time-box">
                    <div class="two-set">
                        <div class="unit">
                            {{ (remainingTime$ | async).secTens }}
                        </div>
                        <div class="unit">
                            {{ (remainingTime$ | async).secOnce }}
                        </div>
                    </div>
                    <div>
                        Second
                    </div>
                </div>
            </div>
            <a class="explore-link" [routerLink]="['/search']" [queryParams]="{ section_id: productSectionData.id, title: productSectionData.title }">
                <span>Explore All</span><mat-icon>arrow_right_alt</mat-icon>
            </a>
        </div>

        <div class="section-header-handset" *ngIf="isHandset$ | async">
            <div class="row">
                <div class="section-title" *ngIf="! hideTitle">
                    {{ productSectionData.title }}
                </div>
                <a class="explore-link" [routerLink]="['/search']"
                    [queryParams]="{ section_id: productSectionData.id }">
                    <span>Explore All</span><mat-icon class="icon">arrow_right_alt</mat-icon>
                </a>
            </div>
            <div class="flash-sale-timer" *ngIf="productSectionData.type === 'flash_sales'">
                <div class="time-box">
                    <div>
                        Day
                    </div>
                    <div class="two-set">
                        <div class="unit">
                            {{ (remainingTime$ | async).dayTens }}
                        </div>
                        <div class="unit">
                            {{ (remainingTime$ | async).dayOnce }}
                        </div>
                    </div>
                </div>
                <div class="time-box">
                    <div>
                        Hour
                    </div>
                    <div class="two-set">
                        <div class="unit">
                            {{ (remainingTime$ | async).hrsTens }}
                        </div>
                        <div class="unit">
                            {{ (remainingTime$ | async).hrsOnce }}
                        </div>
                    </div>
                </div>
                <div class="time-box">
                    <div>
                        Minute
                    </div>
                    <div class="two-set">
                        <div class="unit">
                            {{ (remainingTime$ | async).minTens }}
                        </div>
                        <div class="unit">
                            {{ (remainingTime$ | async).minOnce }}
                        </div>
                    </div>
                </div>
                <div class="time-box">
                    <div>
                        Second
                    </div>
                    <div class="two-set">
                        <div class="unit">
                            {{ (remainingTime$ | async).secTens }}
                        </div>
                        <div class="unit">
                            {{ (remainingTime$ | async).secOnce }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="products-list hide-scroll-bar" #scrollProduct>
            <app-product-card [productData]="product" *ngFor="let product of productSectionData.products"></app-product-card>
        </div>
        <div class="scroll-btn" *ngIf="scrollProduct.scrollWidth > scrollProduct.clientWidth && (isHandset$ | async) === false">
            <div class="prev" *ngIf="productsSectionScroll.showLeft"><button (click)="productsSectionScroll.scrollPrev(scrollProduct)">prev</button></div>
            <div class="next" *ngIf="productsSectionScroll.showRight"><button (click)="productsSectionScroll.scrollNext(scrollProduct)">next</button></div>
        </div>
    </div>
</div>
