<ng-container *ngIf="bannerData$ | async as bannerData">
    <app-banners-section [bannerSectionData]="bannerData[0]"></app-banners-section>
</ng-container>
<ng-container *ngIf="categoryData$ | async as categoryData">
    <div class="container wrapcat_section_homenew">
        <div class="scroll-btn"
            *ngIf="categorySection.scrollWidth > categorySection.clientWidth && (isHandset$ | async) === false">
            <a class="prev" href="javascript:void(0)" *ngIf="categoriesSectionScroll.showLeft"
                (click)="categoriesSectionScroll.scrollCatLeft(categorySection)">Left</a>
        </div>
        <div class="wrap_catsubcat_sec" #categorySection>
            <app-categories-section [categorySectionData]="categoryData[0]"></app-categories-section>
            <app-categories-section [categorySectionData]="categoryData[1]"></app-categories-section>
        </div>
        <div class="scroll-btn"
            *ngIf="categorySection.scrollWidth > categorySection.clientWidth && (isHandset$ | async) === false">
            <a class="next" href="javascript:void(0)" *ngIf="categoriesSectionScroll.showRight"
                (click)="categoriesSectionScroll.scrollCatRight(categorySection)">Right</a>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="bannerData$ | async as bannerData">
    <app-banners-section [bannerSectionData]="bannerData[1]"></app-banners-section>
</ng-container>
<ng-container *ngIf="productData$ | async as productData">
    <app-products-section [productSectionData]="productData[0]" class="light_background_theme"></app-products-section>
</ng-container>

<ng-container *ngIf="bannerData$ | async as bannerData">
    <app-banners-section [bannerSectionData]="bannerData[2]"></app-banners-section>
</ng-container>

<ng-container *ngFor="let categorySection of categoryData$ | async; index as i">
    <ng-container *ngIf="i > 1">
        <app-categories-section [categorySectionData]="categorySection"></app-categories-section>
    </ng-container>
</ng-container>

<ng-container *ngIf="productData$ | async as productData">
    <app-products-section [productSectionData]="productData[1]"></app-products-section>
</ng-container>
<ng-container *ngIf="bannerData$ | async as bannerData">
    <app-banners-section [bannerSectionData]="bannerData[3]"></app-banners-section>
</ng-container>
<ng-container *ngIf="bannerData$ | async as bannerData">
    <app-banners-section [bannerSectionData]="bannerData[4]"></app-banners-section>
</ng-container>
<ng-container *ngIf="homeData$ | async as homeData">
    <app-home-section dataType="reason_to_buy" [homeSectionData]="homeData.reasonToBuy"></app-home-section>
</ng-container>
<ng-container *ngIf="productData$ | async as productData">
    <app-products-section [productSectionData]="productData[2]"></app-products-section>
</ng-container>
<ng-container *ngIf="(homeData$ | async)?.userInfo as userInfo">
    <app-membership-progress [userInfo]="userInfo"></app-membership-progress>
</ng-container>
<ng-container *ngIf="productData$ | async as productData">
    <app-products-section [productSectionData]="productData[3]"></app-products-section>
</ng-container>
<ng-container *ngIf="bannerData$ | async as bannerData">
    <app-banners-section [bannerSectionData]="bannerData[5]"></app-banners-section>
</ng-container>
<ng-container *ngIf="bannerData$ | async as bannerData">
    <app-banners-section [bannerSectionData]="bannerData[6]"></app-banners-section>
</ng-container>
<ng-container *ngIf="brandData$ | async as brandData">
    <app-brands-section [brandSectionData]="brandData"></app-brands-section>
</ng-container>

<ng-container *ngIf="bannerData$ | async as bannerData">
    <app-banners-section [bannerSectionData]="bannerData[7]"></app-banners-section>
</ng-container>
<ng-container *ngIf="bannerData$ | async as bannerData">
    <app-banners-section [bannerSectionData]="bannerData[8]"></app-banners-section>
</ng-container>
<ng-container *ngIf="bannerData$ | async as bannerData">
    <app-banners-section [bannerSectionData]="bannerData[9]"></app-banners-section>
</ng-container>
<ng-container *ngIf="bannerData$ | async as bannerData">
    <app-banners-section [bannerSectionData]="bannerData[10]"></app-banners-section>
</ng-container>
<ng-container *ngIf="productData$ | async as productData">
    <app-products-section [productSectionData]="productData[4]"></app-products-section>
    <app-products-section [productSectionData]="productData[5]"></app-products-section>
</ng-container>
<ng-container *ngFor="let bannerSectionData of bannerData$ | async; index as i">
    <ng-container *ngIf="i > 10">
        <app-banners-section [bannerSectionData]="bannerSectionData"></app-banners-section>
    </ng-container>
</ng-container>
<ng-container *ngIf="productData$ | async as productData">
    <app-products-section [productSectionData]="productData[6]"></app-products-section>
</ng-container>
<ng-container *ngIf="homeData$ | async as homeData">
    <app-home-section dataType="hot_pick" [homeSectionData]="homeData.hotPick"></app-home-section>
</ng-container>
<ng-container *ngIf="homeData$ | async as homeData">
    <app-home-section dataType="app_promo" [homeSectionData]="homeData.appPromotionalContent"></app-home-section>
</ng-container>
<ng-container *ngIf="homeData$ | async as homeData">
    <app-home-section dataType="youtubeVideos" [homeSectionData]="homeData.youtubeVideos"></app-home-section>
</ng-container>
<ng-container *ngIf="homeData$ | async as homeData">
    <app-home-popups [homeData]="homeData"></app-home-popups>
    <!-- <app-home-section dataType="popup" [homeSectionData]="homeData.popup"></app-home-section> -->
</ng-container>
<app-footer *ngIf="isHandset$ | async"></app-footer>
