import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay } from 'rxjs';
import { banner, home_banners_section } from 'src/app/core/interface/banner';
import { ClickRouteService } from 'src/app/core/services/click-route.service';

@Component({
  selector: 'app-banners-description',
  templateUrl: './banners-description.component.html',
  styleUrls: ['./banners-description.component.scss']
})
export class BannersDescriptionComponent {
  @Input() bannerSectionData: home_banners_section = {
    id: 0,
    title: '',
    content: '',
    isVisible: false,
    type: '',
    displaySeeAll: false,
    banners: []
  };
  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

    noBackgroundBanners = [
      'refer_and_earn', 'our_exclusive_1', 'our_exclusive_2'
    ]

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _clickRoute: ClickRouteService
  ) { }

  bannerClick(banner: banner) {
    this._clickRoute.handleClick(banner.attribute, banner.filter, banner.id, '', '', banner.bannerId, banner.banner_type);
  }
}
